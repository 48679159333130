import React from 'react';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';

const StyledPage = styled.body`
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 20vh;
  font-family: 'Raleway', sans-serif;
  background-color: #A3846C;
  min-height: 80vh;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  font-size: 1.3vw;
`
const StyledNavLink = styled(NavLink)`
    line-height: 2.75vw;
    padding-left: 1.25vw;
    &:hover {
        color: brown;
        font-size: 1.5vw;
      }
    color: white;
    text-decoration:none;
`

const StyledText = styled.div`

`

const StyledTitle = styled.div`
      font-size: 2.75vw;
`

const Deca = () => {
    return (
        <StyledPage>
            <Helmet>
                <title>DECA</title>
            </Helmet>
            
            <StyledTitle>
                DECA 🚗
            </StyledTitle>
            <br></br>
            <StyledText>
                My team and I conducted business operations research on a local auto shop. The objective of our analysis was to identify any potential problems or weaknesses in the shop’s business plan and to develop a feasible solution. Our research explored several facets of the auto shop, but was not limited to their market demographics, revenue streams and public relations. After our initial presentation, my team and I were invited to the International Career Development Conference. 

            </StyledText>

            <br></br>
            <br></br>

            <StyledText>
                <StyledNavLink to='/'>
                    Back
                </StyledNavLink>
            </StyledText>

        </StyledPage>
    );
}

export default Deca;