import React from 'react';
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';
const StyledPage = styled.body`
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 20vh;
  font-family: 'Raleway', sans-serif;
  background-color: #A3846C;
  min-height: 80vh;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  font-size: 1.3vw;
`
const StyledNavLink = styled(NavLink)`
    line-height: 2.75vw;
    padding-left: 1.25vw;
    &:hover {
        color: brown;
        font-size: 1.5vw;
      }
    color: white;
    text-decoration:none;
`
const StyledLink = styled.p`
    a {
        line-height: 2.75vw;
        &:hover {
            color: brown;
            font-size: 1.5vw;
        }
        color: white;
        text-decoration:none;
    }
`

const StyledText = styled.div`

`

const StyledTitle = styled.div`
       font-size: 2.75vw;
`

const Sourceamerica = () => {
    return (
        <StyledPage>
            <Helmet>
                <title>SourceAmerica</title>
            </Helmet>

            <StyledTitle>
                Source America Design Challenge ✂️
            </StyledTitle>
            <br></br>
            <StyledText>
            The goal of our project was to aid a person with disabilities in their job. Too often, devices and tools are not designed for people with disabilities, in mind. We wanted to make an impact on someone’s life by giving them the ability to be more independent. Together, my team and I worked with a local non-profit farm that employed people with disabilities. By connecting with the employees on the farm we were able to identify a common problem with shredding papers and together developed a solution that would increase their independence. 

            </StyledText>
           
            <br></br>
            
            <StyledText>
                <StyledLink>
                    <a href='https://docs.google.com/document/d/1Z_L6j1ReYR0MVFecvbsvDA3ck1SxP3yeMGwmP4XBGj4/edit?usp=sharing'
                        target="_blank">
                        Paper
                    </a>
                </StyledLink>
            </StyledText>

            <br></br>
            <br></br>

            <StyledText>
                <StyledNavLink to='/'>
                    Back
                </StyledNavLink>
            </StyledText>
            
        </StyledPage>
    );
}

export default Sourceamerica;