import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Homepage from './pages/homepage';
import Deca from './pages/deca';
import Sourceamerica from './pages/sa';
import Internship from './pages/internship';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Route path="/deca" component={Deca} />
        <Route path="/sourceamerica" component={Sourceamerica} />
        <Route path="/ghidra" component={Internship} />
      </Switch>
    </Router>
  );
}

export default App;
