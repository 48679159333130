import React from 'react';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';
const StyledPage = styled.body`
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 20vh;
  font-family: 'Raleway', sans-serif;
  background-color: #A3846C;
  min-height: 80vh;
  color: white;
  text-shadow: 2px 2px 4px  #000000;
  font-size: 1.3vw;
`
const StyledNavLink = styled(NavLink)`
    line-height: 2.75vw;
    padding-left: 1.25vw;
    &:hover {
        color: brown;
        font-size: 1.5vw;
      }
    color: white;
    text-decoration:none;
`
const StyledLink = styled.div`
    a {
        &:hover {
            color: brown;
            font-size: 1.3vw;
        }
        color: white;
        text-decoration:none;
    }
`


const StyledText = styled.div`

`

const StyledTitle = styled.div`
        font-size: 2.75vw;
`

const Internship = () => {
    return (
        <StyledPage>
            <Helmet>
                <title>Ghidra </title>
            </Helmet>

            <StyledTitle>
                Ghidra Research 🐲
            </StyledTitle>
            <br></br>
            <StyledText>
                Over summer in 2019, I was fortunate to work under the mentorship of Dr. Anwar Mamat at the University of Maryland. My topics of research included reverse engineering techniques and symbolic execution. 
                I used 
                <a href="https://ghidra-sre.org/"
                    target="_blank">
                        &nbsp;Ghidra (NSA)&nbsp;
                        
                    </a>
                
                and 
                <a href="https://angr.io/"
                    target="_blank">
                        &nbsp;Angr (UCSB)&nbsp;
                    </a>
                
                to explore these respective topics. I additionally tested scripts on CTF programs which are created with built in cybersecurity flaws to be discovered.
            </StyledText>

            <br></br>
            <br></br>

            <StyledText>
                <StyledNavLink to='/'>
                    Back
                </StyledNavLink>
            </StyledText>
            
        </StyledPage>
    
    );
}

export default Internship;