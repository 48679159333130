import React from 'react';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';

const StyledPage = styled.body`
  padding-left: 15vw;
  padding-top: 20vh;
  font-family: 'Raleway', sans-serif;
  background-color: #A3846C;
  min-height: 80vh;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  font-size: 1.3vw;
`
const StyledNavLink = styled(NavLink)`
    line-height: 2.75vw;
    padding-left: 1.25vw;
    &:hover {
        color: brown;
        font-size: 1.5vw;
      }
    color: white;
    text-decoration:none;
`

const StyledText = styled.div`

`

const StyledTitle = styled.div`
      font-size: 3.5vw;
`

const Homepage = () => {
    return (
        <StyledPage>
            <Helmet>
                <title>Rohan Aluka</title>
            </Helmet>
            
            <StyledTitle>
                ☕
            </StyledTitle>
            
            <StyledTitle>
                Hi, I'm Rohan Aluka!
            </StyledTitle>

            <StyledText>
                <br/>
                I'm currently a freshman studying Finance and Math at NYU Stern and NYU Courant.
                <br/>
                I'm interested in math, finance, and tech.
                <br/>
                Here are some projects I've worked on:
                <br/> &nbsp;
            </StyledText>
                
            <StyledText>
                <StyledNavLink to='/deca'>
                    DECA 🚗
                </StyledNavLink>
            </StyledText>

            <StyledText>
                <StyledNavLink to='/sourceamerica'>
                    SourceAmerica ✂️
                </StyledNavLink>
            </StyledText>

            <StyledText>
                <StyledNavLink to='/ghidra'>
                    Ghidra Research 🐲
                </StyledNavLink>
            </StyledText>

        </StyledPage>
    );
}

export default Homepage;